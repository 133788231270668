<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-accent"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
               <div class="form-body">
                    <div class="row">
                        <div class="col l-6 m-6 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="countryName" @md-selected="getCountrySelected" :md-options="countries" @md-changed="getCountries"  @md-opened="getCountries" :class="{'md-invalid': submitted && $v.entity.countryId.$error }">
                                    <label>Quốc gia</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.countryName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.countryId.isSelected">Vui lòng chọn quốc gia</span>
                                </md-autocomplete>
                                <md-button @click="openCountry()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                            <md-field :class="{'md-invalid': submitted && $v.entity.brandCode.$error }">
                                <label for="brandCode">Mã thương hiệu</label>
                                <md-input name="brandCode" v-model="entity.brandCode"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.brandCode.required">Vui lòng nhập mã thương hiệu</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.brandName.$error }">
                                <label for="brandName">Tên thương hiệu</label>
                                <md-input name="brandName" v-model="entity.brandName"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.brandName.required">Vui lòng nhập tên thương hiệu</span>
                            </md-field>

                            <div class="form-control">
                                <md-radio v-model="entity.isActive" :value="true">Hoạt động</md-radio>
                                <md-radio v-model="entity.isActive" :value="false">Khóa</md-radio>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <countryList ref="countryList" @close="closeCountry"/>
   </div>
</template>
<script>
    import brandService from '../../../api/brandService';
    import countryService from '../../../api/countryService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import countryList from '../../../components/popup/_CountryList.vue'

    export default ({
        components: {
            countryList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật thương hiệu phương tiện'
        },
        data() {
            return {
               title: '',
               id: 0,
               submitted: false,
               entity: { id: 0, countryId: 0, brandCode: '', brandName: '', isActive: true },
               countryName: '',
               countries: []
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật thương hiệu phương tiện';
               this.getById();
            }
            else{
               this.title = 'Thêm mới thương hiệu phương tiện';
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            closeCountry(item){
                this.countryName = item.countryName;
                this.entity.countryId = item.id;
                this.$refs.countryList.close();
            },

            getCountrySelected(val){
                this.entity.countryId = val.id;
                this.countryName = val.countryName;
            },

            openCountry(){
                this.$refs.countryList.open();
            },

            getCountries(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code: '', name:  searchTerm };
                countryService.getCountries(search).then((response) => {
                    if(response.statusCode == 200){
                        this.countries = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                brandService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                brandService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/brand');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                brandService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.countryName = this.entity.country.countryName;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            back(){
                this.$router.push('/brand');
            }
        },
        watch: { 
            countryName: function (val) { 
                if(val == ''){
                    this.entity.countryId = 0;
                }
            }
        },
        validations: {
            entity: {
                countryId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                brandCode: { required },
                brandName: { required }
            }
       }
     })

</script>
